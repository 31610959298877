import { ReactNode, useRef } from 'react';

import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { Widget } from 'src/models/employerConstructor/widget.types';

interface WidgetTipProps {
    children: ReactNode;
    code: Widget.Text | Widget.Description | Widget.Gallery | Widget.Picture | Widget.Video;
}

const TrlKeys = {
    [Widget.Text]: 'employer.constructor.widget.TEXT.tip',
    [Widget.Description]: 'employer.constructor.widget.DESCRIPTION.tip',
    [Widget.Gallery]: 'employer.constructor.widget.GALLERY.tip',
    [Widget.Picture]: 'employer.constructor.widget.PICTURE.tip',
    [Widget.Video]: 'employer.constructor.widget.VIDEO.tip',
};

const WidgetTip: TranslatedComponent<WidgetTipProps> = ({ children, code, trls }) => {
    const activatorRef = useRef(null);
    return (
        <HoverTip
            host={!process.env.SSR ? document.body : null}
            placement={TipPlacement.Right}
            render={() => trls[TrlKeys[code]]}
            activatorRef={activatorRef}
        >
            <div ref={activatorRef}>{children}</div>
        </HoverTip>
    );
};

export default translation(WidgetTip);
