import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import brandyEmployerConstructorLoadVideoButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/brandy_employer_templates/brandy_employer_constructor_load_video_button_click';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import InputText from 'bloko/blocks/inputText';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ErrorComponent from 'src/components/EmployerConstructor/ErrorComponent';
import WidgetTip from 'src/components/EmployerConstructor/WidgetTip';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';
import {
    employerConstructorModifyWidget,
    EmployerConstructorVideoWidget,
    WidgetComponentProps,
} from 'src/models/employerConstructor';
import { Widget } from 'src/models/employerConstructor/widget.types';

import WidgetControls from 'src/components/EmployerConstructor/widgets/WidgetControls';
import WidgetWrapper from 'src/components/EmployerConstructor/widgets/WidgetWrapper';
import HeightByRatio from 'src/components/EmployerConstructor/widgets/components/HeightByRatio';
import WidgetEditDescription from 'src/components/EmployerConstructor/widgets/components/WidgetEditDescription';
import WidgetEditDescriptionIcon, {
    WidgetEditDescriptionIconType,
} from 'src/components/EmployerConstructor/widgets/components/WidgetEditDescriptionIcon';
import WidgetEditDescriptionText from 'src/components/EmployerConstructor/widgets/components/WidgetEditDescriptionText';
import getVideoIframeSrc, { getHosting } from 'src/components/EmployerConstructor/widgets/getVideoIframeSrc';

import styles from './video-widget.less';

const VIDEO_RATIO = 16 / 9;

const TrlKeys = {
    widgetVideoEditText: 'employer.constructor.widgetedit.video',
    widgetVideoName: 'employer.constructor.widgetname.video',
    description: 'employer.constructor.widget.video.description',
    inputPlaceholder: 'employer.constructor.widget.video.inputPlaceholder',
    uploadButton: 'employer.constructor.widget.video.uploadButton',
    cancelEdit: 'employer.constructor.widget.video.cancelEdit',
    saveVideo: 'employer.constructor.widget.video.saveVideo',
    invalidMessage: 'employer.constructor.widget.video.invalid',
};

const VideoWidget: TranslatedComponent<WidgetComponentProps<EmployerConstructorVideoWidget>> = ({
    trls,
    editMode,
    id,
    url,
    getMovedElementProps,
    dragged,
    hasDraggedElements,
}) => {
    const [localEditMode, toggleLocalEditMode, setLocalEditMode] = useToggleState(!url);
    const [localUrl, setLocalUrl] = useState(url || '');
    const [invalid, setInvalid] = useState(false);
    const dispatch = useDispatch();
    const isMagritteEmployerPageHeaderExp = useSelector((state) => state.isMagritteEmployerPageHeaderExp);
    const viewReady = !!url;

    const cancelEdit = useCallback(() => {
        setLocalUrl(url);
        setInvalid(false);
        setLocalEditMode(false);
    }, [setLocalEditMode, url]);

    const saveVideo = useCallback(() => {
        if (!getVideoIframeSrc(localUrl)) {
            setInvalid(true);
            brandyEmployerConstructorLoadVideoButtonClick({
                url: localUrl,
                videoHostringType: getHosting(localUrl),
                isValid: false,
            });
            return;
        }

        brandyEmployerConstructorLoadVideoButtonClick({
            url: localUrl,
            videoHostringType: getHosting(localUrl),
            isValid: true,
        });
        dispatch(
            employerConstructorModifyWidget({
                id,
                url: localUrl,
            })
        );
        setLocalEditMode(false);
    }, [dispatch, id, localUrl, setLocalEditMode]);

    const view = useCallback(
        () => (
            <div
                className={classNames(styles.widgetVideo, {
                    [styles.widgetVideoMagritte]: isMagritteEmployerPageHeaderExp,
                })}
            >
                <HeightByRatio ratio={VIDEO_RATIO}>
                    <iframe
                        className={classNames(styles.widgetVideoFrame, {
                            [styles.widgetVideoFrameOverlap]: hasDraggedElements,
                        })}
                        src={getVideoIframeSrc(localUrl) || ''}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </HeightByRatio>
            </div>
        ),
        [hasDraggedElements, isMagritteEmployerPageHeaderExp, localUrl]
    );

    const edit = useCallback(() => {
        return (
            <WidgetTip code={Widget.Video}>
                <WidgetControls
                    upload={false}
                    getMovedElementProps={getMovedElementProps}
                    edit={viewReady && !localEditMode}
                    editText={trls[TrlKeys.widgetVideoEditText]}
                    onEdit={toggleLocalEditMode}
                    id={id}
                    name={trls[TrlKeys.widgetVideoName]}
                />
                {localEditMode && (
                    <WidgetEditDescription>
                        <WidgetEditDescriptionText>
                            <VSpacing base={2} />
                            <div>{trls[TrlKeys.description]}</div>
                            <VSpacing base={2} />
                            <div>
                                <InputText
                                    value={localUrl}
                                    onChange={setLocalUrl}
                                    onFocus={() => setInvalid(false)}
                                    placeholder={trls[TrlKeys.inputPlaceholder]}
                                    invalid={invalid}
                                    autoComplete="video-widget"
                                />
                                <ErrorComponent show={invalid}>{trls[TrlKeys.invalidMessage]}</ErrorComponent>
                            </div>
                            <VSpacing base={2} />
                            <div>
                                <Button onClick={saveVideo} kind={ButtonKind.Success}>
                                    {!url ? trls[TrlKeys.uploadButton] : trls[TrlKeys.saveVideo]}
                                </Button>
                                {url && (
                                    <FormSpacer>
                                        <Button onClick={cancelEdit}>{trls[TrlKeys.cancelEdit]}</Button>
                                    </FormSpacer>
                                )}
                            </div>
                        </WidgetEditDescriptionText>
                        <WidgetEditDescriptionIcon iconType={WidgetEditDescriptionIconType.Video} />
                    </WidgetEditDescription>
                )}
                {!localEditMode && viewReady && view()}
            </WidgetTip>
        );
    }, [
        cancelEdit,
        getMovedElementProps,
        id,
        invalid,
        localEditMode,
        localUrl,
        saveVideo,
        toggleLocalEditMode,
        trls,
        url,
        view,
        viewReady,
    ]);

    return <WidgetWrapper dragged={dragged} edit={edit} view={view} editMode={editMode} viewReady={viewReady} />;
};

export default translation(VideoWidget);
