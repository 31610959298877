import { useRef } from 'react';
import classnames from 'classnames';

import HoverTip, { TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import capitalize from 'bloko/common/capitalize';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { Widget, AddWidgetButtonType } from 'src/models/employerConstructor/widget.types';

import AddWidgetButtonTip from 'src/components/EmployerConstructor/AddWidgetButtonTip';

import styles from './add-widget-button.less';

const TrlKeys = {
    [Widget.Text]: 'employer.constructor.widget.type.text',
    [Widget.Picture]: 'employer.constructor.widget.type.picture',
    [Widget.Address]: 'employer.constructor.widget.type.address',
    [Widget.Gallery]: 'employer.constructor.widget.type.gallery',
    [Widget.Video]: 'employer.constructor.widget.type.video',
    [Widget.Separator]: 'employer.constructor.widget.type.separator',
};

interface AddWidgetButtonProps {
    onClick: () => void;
    type: AddWidgetButtonType;
    count: number;
    limit?: number;
}

const AddWidgetButton: TranslatedComponent<AddWidgetButtonProps> = ({ onClick, type, limit, count, trls }) => {
    const typeLower = type.toLowerCase();
    const disabled = limit && count >= limit;
    const imageClassName = `addWidgetTypeImage${capitalize(type)}`;
    const activatorRef = useRef(null);

    const renderIcon = (
        <div
            data-qa={`add-widget-${typeLower}`}
            className={classnames(styles.addWidgetType, {
                [styles.addWidgetTypeLimit]: disabled,
                [styles.addWidgetTypeActive]: !disabled,
            })}
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
            ref={activatorRef}
        >
            <div className={classnames(styles.addWidgetTypeImage, styles[imageClassName])} />
            {trls[TrlKeys[type]]}
        </div>
    );
    return (
        <HoverTip
            host={!process.env.SSR ? document.body : null}
            render={() => <AddWidgetButtonTip count={count} limit={limit} type={type} />}
            layer={TipLayer.Overlay}
            activatorRef={activatorRef}
        >
            {renderIcon}
        </HoverTip>
    );
};

export default translation(AddWidgetButton);
