import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import constructorSaveFormFieldTouch from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/constructor_save_form_field_touch';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import scrollToElement from 'Utils/ScrollToElement';
import TextContentEditor from 'src/components/Employer/TextContentEditor';
import WidgetTip from 'src/components/EmployerConstructor/WidgetTip';
import { textHasError, ConstructorWidgetValidateError } from 'src/components/EmployerConstructor/validate';
import RawUserContent from 'src/components/RawUserContent';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    employerConstructorModifyWidget,
    EmployerConstructorTextWidget,
    WidgetComponentProps,
} from 'src/models/employerConstructor';
import { Widget } from 'src/models/employerConstructor/widget.types';

import WidgetControls from 'src/components/EmployerConstructor/widgets/WidgetControls';
import WidgetWrapper from 'src/components/EmployerConstructor/widgets/WidgetWrapper';

const TrlKeys = {
    widgetTextName: 'employer.constructor.widgetname.text',
    lengthError: 'employer.constructor.widget.text.invalid',
};

const TextWidget: TranslatedComponent<WidgetComponentProps<EmployerConstructorTextWidget>> = ({
    trls,
    editMode,
    content,
    id,
    invalid,
    invalidOnSave,
    getMovedElementProps,
    dragged,
    hasDraggedElements,
}) => {
    const dispatch = useDispatch();
    const textMaxLength = useSelector((state) => state.employerConstructorSettings.widgetSettings?.textMaxLength);
    const viewReady = !!content;
    const widgetWrapperRef = useRef(null);

    const view = useCallback(() => {
        return (
            <Text size={TextSize.Large}>
                <RawUserContent content={content || ''} />
            </Text>
        );
    }, [content]);

    const updateContent = useCallback(
        (content: string | null) => {
            content !== null &&
                dispatch(
                    employerConstructorModifyWidget({
                        id,
                        content,
                    })
                );
        },
        [dispatch, id]
    );

    const checkInvalid = useCallback(() => {
        dispatch(
            employerConstructorModifyWidget({
                id,
                invalid:
                    textHasError(
                        {
                            content,
                        },
                        textMaxLength
                    ) === ConstructorWidgetValidateError.InvalidLength,
            })
        );
    }, [content, dispatch, id, textMaxLength]);
    const clearInvalid = useCallback(() => {
        dispatch(
            employerConstructorModifyWidget({
                id,
                invalid: false,
            })
        );
    }, [dispatch, id]);
    useEffect(() => {
        if (invalidOnSave) {
            scrollToElement(widgetWrapperRef.current, {
                centered: false,
                topOffset: 0,
            });
            dispatch(
                employerConstructorModifyWidget({
                    id,
                    invalidOnSave: false,
                })
            );
        }
    }, [dispatch, id, invalidOnSave]);
    const edit = useCallback(() => {
        return (
            <WidgetTip code={Widget.Text}>
                <WidgetControls
                    getMovedElementProps={getMovedElementProps}
                    edit={false}
                    upload={false}
                    name={trls[TrlKeys.widgetTextName]}
                    id={id}
                />
                <TextContentEditor
                    value={content}
                    onChange={updateContent}
                    onBlur={checkInvalid}
                    onFocus={() => {
                        clearInvalid();
                        constructorSaveFormFieldTouch({ fieldName: 'text' });
                    }}
                    overlapFrame={hasDraggedElements}
                    invalid={invalid}
                />
            </WidgetTip>
        );
    }, [
        getMovedElementProps,
        trls,
        id,
        content,
        updateContent,
        checkInvalid,
        clearInvalid,
        hasDraggedElements,
        invalid,
    ]);

    return (
        <WidgetWrapper
            dragged={dragged}
            edit={edit}
            view={view}
            editMode={editMode}
            viewReady={viewReady}
            invalid={
                invalid
                    ? format(trls[TrlKeys.lengthError], {
                          '{0}': textMaxLength || 0,
                      })
                    : undefined
            }
            ref={widgetWrapperRef}
        />
    );
};

export default translation(TextWidget);
