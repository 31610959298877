import usePictureSettings from 'src/components/EmployerConstructor/usePictureSettings';
import { useSelector } from 'src/hooks/useSelector';
import { PictureType, PictureSettings } from 'src/models/employerConstructor/settings';
import { Widget } from 'src/models/employerConstructor/widget.types';

interface UseImageCropSettingsResult {
    pictureType?: PictureType;
    imageCropSettings: PictureSettings;
}

const useImageCropSettings = (): UseImageCropSettingsResult => {
    const pictureType = useSelector(
        (state) => state.employerConstructorSettings?.widgetSettings?.pictureTypeByWidgetType?.[Widget.Picture]
    );

    const imageCropSettings = usePictureSettings(pictureType || PictureType.PictureWidget);

    return { pictureType, imageCropSettings };
};

export default useImageCropSettings;
