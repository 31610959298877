import { EmployerAddress } from 'src/models/employerAddresses.types';
import {
    AnyWidget,
    EmployerConstructorAddressWidget,
    EmployerConstructorGalleryWidget,
    EmployerConstructorImage,
    EmployerConstructorPictureWidget,
    EmployerConstructorTextWidget,
    EmployerConstructorVideoWidget,
    EmployerConstructorWidget,
} from 'src/models/employerConstructor';
import { EmployerConstructorSettings } from 'src/models/employerConstructor/settings';
import { Widget } from 'src/models/employerConstructor/widget.types';

import getVideoIframeSrc from 'src/components/EmployerConstructor/widgets/getVideoIframeSrc';

export enum ConstructorWidgetValidateError {
    Empty = 'EMPTY',
    Invalid = 'INVALID',
    InvalidLength = 'INVALID_LENGTH',
    UnknownType = 'UNKNOWN_TYPE',
}

export const addressHasError = (
    widget: EmployerConstructorAddressWidget,
    addresses: EmployerAddress[]
): ConstructorWidgetValidateError | null => {
    if (!widget.addressId) {
        return ConstructorWidgetValidateError.Empty;
    }
    return !addresses.some((address) => {
        return address.id === widget.addressId;
    })
        ? ConstructorWidgetValidateError.Invalid
        : null;
};
export const textHasError = (
    widget: Partial<EmployerConstructorTextWidget>,
    textMaxLength = 0
): ConstructorWidgetValidateError | null => {
    if (!widget.content?.trim().length) {
        return ConstructorWidgetValidateError.Empty;
    } else if (widget.content?.length > textMaxLength) {
        return ConstructorWidgetValidateError.InvalidLength;
    }
    return null;
};
export const galleryHasError = (widget: EmployerConstructorGalleryWidget): ConstructorWidgetValidateError | null => {
    if (!widget.items?.length) {
        return ConstructorWidgetValidateError.Empty;
    }
    return null;
};
export const pictureHasError = (
    widget: EmployerConstructorPictureWidget,
    pictures: EmployerConstructorImage[]
): ConstructorWidgetValidateError | null => {
    if (!widget.pictureId) {
        return ConstructorWidgetValidateError.Empty;
    }
    return !pictures?.some((picture) => {
        return picture.pictureId === widget.pictureId;
    })
        ? ConstructorWidgetValidateError.Invalid
        : null;
};
export const videoHasError = (
    widget: EmployerConstructorVideoWidget,
    videoUrlMaxLength = 0
): ConstructorWidgetValidateError | null => {
    if (!widget.url?.trim()) {
        return ConstructorWidgetValidateError.Empty;
    }
    if (widget.url?.length > videoUrlMaxLength) {
        return ConstructorWidgetValidateError.InvalidLength;
    }
    if (!getVideoIframeSrc(widget.url)) {
        return ConstructorWidgetValidateError.Invalid;
    }
    return null;
};

interface ValidateParams {
    widgets: EmployerConstructorWidget[];
    images: EmployerConstructorImage[];
    addresses: EmployerAddress[];
    settings: EmployerConstructorSettings;
}

export interface InvalidWidget {
    widget: EmployerConstructorWidget;
    error: ConstructorWidgetValidateError;
}

export const isInvalidWidget = (widget: unknown): widget is InvalidWidget => !!(widget as InvalidWidget).error;

export default ({ widgets, images, settings, addresses }: ValidateParams): InvalidWidget[] => {
    return widgets
        .map((rawWidget) => {
            const widget = rawWidget as AnyWidget;
            let error = null;
            switch (widget.type) {
                case Widget.Gallery:
                    error = galleryHasError(widget);
                    break;
                case Widget.Picture:
                    error = pictureHasError(widget, images);
                    break;
                case Widget.Address:
                    error = addressHasError(widget, addresses);
                    break;
                case Widget.Text:
                    error = textHasError(widget, settings.widgetSettings?.textMaxLength);
                    break;
                case Widget.Video:
                    error = videoHasError(widget, settings.widgetSettings?.videoUrlMaxLength);
                    break;
                case Widget.Separator:
                    error = null;
                    break;
                default:
                    error = ConstructorWidgetValidateError.UnknownType;
            }
            return {
                widget: rawWidget,
                error,
            };
        })
        .filter<InvalidWidget>(isInvalidWidget);
};
