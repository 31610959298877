import { useCallback, useMemo } from 'react';
import Dropzone, { DropzoneRenderFunction } from 'react-dropzone';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import WidgetTip from 'src/components/EmployerConstructor/WidgetTip';
import { GetMovedElementProps } from 'src/components/EmployerConstructor/drag/DragElement';
import getFileFromEvent from 'src/components/EmployerConstructor/getFileFromEvent';
import WidgetControls from 'src/components/EmployerConstructor/widgets/WidgetControls';
import HeightByRatio from 'src/components/EmployerConstructor/widgets/components/HeightByRatio';
import LoadArea from 'src/components/EmployerConstructor/widgets/components/LoadArea';
import { employerConstructorInvalidPictureSize } from 'src/components/Notifications/EmployerConstructor';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useToggleState from 'src/hooks/useToggleState';
import { Widget } from 'src/models/employerConstructor/widget.types';

import ImageCropPopupContainer from 'src/components/EmployerConstructor/widgets/GalleryWidget/containers/ImageCropContainer';
import SideBarContainer from 'src/components/EmployerConstructor/widgets/GalleryWidget/containers/SideBarContainer';
import SlidesContainer from 'src/components/EmployerConstructor/widgets/GalleryWidget/containers/SlidesContainer';
import useGalleryActions from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useActions';
import useImageCrop from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useImageCrop';
import { PreparedPicture } from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/usePictures';
import useSettings from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useSettings';
import useSlides, {
    SLIDE_TYPE_LOAD_AREA,
} from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useSlides';

import styles from './gallery-widget.less';

const TrlKeys = {
    loadAreaDescription: 'employer.constructor.widget.image.loadareadescription',
    uploadButton: 'employer.constructor.widget.image.uploadButton',
    widgetName: 'employer.constructor.widget.type.gallery',
    addOrUpdate: 'employer.constructor.widget.gallery.addOrUpdate',
};

const convertToMb = (bytes: number) => Math.floor(bytes / 1024 / 1024);

const makeLoadAreaHintText = (template: string, minWidth: number, minHeight: number, maxSizeBytes: number) =>
    format(template, {
        '{0}': minWidth,
        '{1}': minHeight,
        '{2}': convertToMb(maxSizeBytes),
    });

interface EditModeContainerProps {
    pictures: PreparedPicture[];
    widgetId: number;
    getMovedElementProps?: GetMovedElementProps;
}

const EditModeContainer: TranslatedComponent<EditModeContainerProps> = ({
    pictures,
    widgetId,
    getMovedElementProps,
    trls,
}) => {
    const { pictureSettings, widgetSettings } = useSettings();
    const { cropPictureProps, setCropPictureId } = useImageCrop();
    const {
        isUploading,
        isResizing,
        enhancedUploadPicture,
        enhancedResizePicture,
        resizePictureNoChanges,
        removePicture,
        setWidgetItemsByIds,
        showResizePictureInterface,
    } = useGalleryActions({
        widgetId,
        setCropPictureId,
    });
    const [isOpenSidebar, toggleOpenSidebar] = useToggleState(false);
    const [slides, activeSlide, setActiveSlide] = useSlides(pictures);
    const isCanAddPicture = widgetSettings ? pictures.length < widgetSettings.galleryMaxItemsCount : false;
    const { addNotification } = useNotification();

    const editImage = useCallback(
        (pictureId: number) => {
            if (pictureId) {
                const picture = pictures.find((pic) => pic.id === pictureId);

                if (
                    picture &&
                    (picture.originalWidth < pictureSettings.minWidth ||
                        picture.originalHeight < pictureSettings.minHeight)
                ) {
                    addNotification(employerConstructorInvalidPictureSize, {
                        props: {
                            minimumWidth: pictureSettings.minWidth,
                            minimumHeight: pictureSettings.minHeight,
                        },
                    });
                    return;
                }
                showResizePictureInterface(pictureId);
            }
        },
        [addNotification, pictureSettings.minHeight, pictureSettings.minWidth, pictures, showResizePictureInterface]
    );

    const renderLoadArea: DropzoneRenderFunction = useCallback(
        ({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={styles.widgetGalleryLoadArea}>
                <LoadArea
                    isLoading={isUploading}
                    disabled={!isCanAddPicture}
                    inputProps={getInputProps()}
                    hintText={makeLoadAreaHintText(
                        trls[TrlKeys.loadAreaDescription],
                        pictureSettings.minWidth,
                        pictureSettings.minHeight,
                        pictureSettings.maxSizeBytes
                    )}
                    buttonText={trls[TrlKeys.uploadButton]}
                />
            </div>
        ),
        [
            isCanAddPicture,
            isUploading,
            pictureSettings.maxSizeBytes,
            pictureSettings.minHeight,
            pictureSettings.minWidth,
            trls,
        ]
    );

    const slidesWithLoadArea = useMemo(
        () => [
            ...slides,
            {
                id: SLIDE_TYPE_LOAD_AREA,
                content: (
                    <Dropzone
                        multiple={false}
                        disableClick={true}
                        accept={pictureSettings.allowedMimeTypes}
                        onDrop={
                            !isCanAddPicture ? undefined : (event) => enhancedUploadPicture(getFileFromEvent(event))
                        }
                    >
                        {renderLoadArea}
                    </Dropzone>
                ),
            },
        ],
        [enhancedUploadPicture, isCanAddPicture, pictureSettings.allowedMimeTypes, renderLoadArea, slides]
    );
    const isActiveLoadArea = activeSlide === slidesWithLoadArea.length;

    const slidesContainer = (
        <SlidesContainer slides={slidesWithLoadArea} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
    );

    return (
        <WidgetTip code={Widget.Gallery}>
            <WidgetControls
                getMovedElementProps={getMovedElementProps}
                upload={false}
                edit={!isActiveLoadArea}
                onEdit={toggleOpenSidebar}
                id={widgetId}
                name={trls[TrlKeys.widgetName]}
                editText={trls[TrlKeys.addOrUpdate]}
            />
            <div className={styles.widgetGallery}>
                {pictures.length > 0 ? (
                    <HeightByRatio ratio={pictureSettings.widthHeightRatio}>{slidesContainer}</HeightByRatio>
                ) : (
                    slidesContainer
                )}
                {isOpenSidebar && !isActiveLoadArea && (
                    <SideBarContainer
                        pictures={pictures}
                        canAddPicture={isCanAddPicture}
                        editImage={editImage}
                        setOrder={setWidgetItemsByIds}
                        uploadImage={enhancedUploadPicture}
                        removePicture={removePicture}
                        isUploading={isUploading}
                        pictureSettings={pictureSettings}
                    />
                )}
            </div>
            {cropPictureProps.pictureId && (
                <ImageCropPopupContainer
                    {...cropPictureProps}
                    pictureId={cropPictureProps.pictureId}
                    noChanges={resizePictureNoChanges}
                    onSave={enhancedResizePicture}
                    resizeInProgress={isResizing}
                />
            )}
        </WidgetTip>
    );
};

export default translation(EditModeContainer);
