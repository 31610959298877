import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Dropzone, { DropFilesEventHandler, GetInputPropsFn, DropzoneRenderFunction } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { CameraOutlinedSize16, PenSquareOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonIconPosition } from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format, formatNewLine } from 'bloko/common/trl';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import CdnImg from 'src/components/CdnImg';
import WidgetTip from 'src/components/EmployerConstructor/WidgetTip';
import resizeImage from 'src/components/EmployerConstructor/resizeImage';
import uploadImage from 'src/components/EmployerConstructor/uploadImage';
import ImageCropPopup, { ImageCropResultHandler } from 'src/components/ImageCropPopup';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    Status,
    employerConstructorModifyWidget,
    setModalError,
    EMPTY_ERROR_STATE,
    EmployerConstructorPictureWidget,
    WidgetComponentProps,
} from 'src/models/employerConstructor';
import { Widget } from 'src/models/employerConstructor/widget.types';

import WidgetControls from 'src/components/EmployerConstructor/widgets/WidgetControls';
import WidgetControlsButtonWrapper from 'src/components/EmployerConstructor/widgets/WidgetControlsButtonWrapper';
import WidgetWrapper from 'src/components/EmployerConstructor/widgets/WidgetWrapper';
import FileLoadButton from 'src/components/EmployerConstructor/widgets/components/FileLoadButton';
import LoadArea from 'src/components/EmployerConstructor/widgets/components/LoadArea';
import useImageCropSettings from 'src/components/EmployerConstructor/widgets/useImageCropSettings';

import styles from './image-widget.less';

const TrlKeys = {
    loadAreaDescription: 'employer.constructor.widget.image.loadareadescription',
    uploadButton: 'employer.constructor.widget.image.uploadButton',
    widgetImageName: 'employer.constructor.widgetname.image',
    widgetImageUploadText: 'employer.constructor.widgetupload.text',
    cropPopupTitle: 'employer.constructor.widget.image.cropimage.title',
    cropPopupDescription: 'employer.constructor.widget.image.cropimage.description',
    cropPopupSave: 'employer.constructor.cropimage.button.save',
    cropPopupCancel: 'employer.constructor.cropimage.button.cancel',
    invalidImage: 'employer.constructor.widget.image.invalid',
};

const ImageWidget: TranslatedComponent<WidgetComponentProps<EmployerConstructorPictureWidget>> = ({
    trls,
    id,
    resizeStatus,
    editMode,
    pictureId,
    getMovedElementProps,
    dragged,
}) => {
    const dispatch = useDispatch();
    const { pictureType, imageCropSettings } = useImageCropSettings();
    const images = useSelector((state) => state.employerConstructor.images);
    const modalError = useSelector((state) => state.employerConstructor.modalError);
    const isMagritteEmployerPageHeaderExp = useSelector((state) => state.isMagritteEmployerPageHeaderExp);
    const [cropPictureId, setCropPictureId] = useState<number | null>(null);
    const cropPicture = useMemo(
        () => images.find((image) => image.pictureId === cropPictureId),
        [cropPictureId, images]
    );
    const imageParams = useMemo(() => images.find((image) => image.pictureId === pictureId), [images, pictureId]);
    const { addNotification } = useNotification();
    const activatorRef = useRef(null);

    const viewReady = !!imageParams?.path;
    const resetModalError = useCallback(() => dispatch(setModalError(EMPTY_ERROR_STATE)), [dispatch]);

    /**
     * Чистим ошибка crop модалки при закрытии окна
     */
    useEffect(() => {
        !cropPictureId && resetModalError();
    }, [cropPictureId, resetModalError]);

    const onUpload: DropFilesEventHandler = useCallback(
        (accepted) => {
            const imageFile = accepted[0];
            dispatch(
                uploadImage(
                    {
                        file: imageFile,
                        pictureType,
                        widgetId: id,
                        hasAlreadyImage: !!pictureId,
                    },
                    addNotification
                )
            )
                .then((data) => {
                    setCropPictureId(data.pictureId);
                    dispatch(
                        employerConstructorModifyWidget({
                            resizeStatus: Status.Dirty,
                            id,
                        })
                    );
                    return null;
                })
                .catch(console.error);
        },
        [addNotification, dispatch, id, pictureId, pictureType]
    );

    const onSavePicture: ImageCropResultHandler = useCallback(
        ({ noChanges, absoluteSizes }) => {
            if (!cropPictureId) {
                return;
            }
            if (noChanges) {
                pictureId !== cropPictureId &&
                    dispatch(
                        employerConstructorModifyWidget({
                            id,
                            pictureId: cropPictureId,
                        })
                    );
                setCropPictureId(null);
                return;
            }
            resetModalError();
            dispatch(
                resizeImage(
                    {
                        pictureId: cropPictureId,
                        resizeParams: absoluteSizes,
                        widgetId: id,
                    },
                    setModalError,
                    addNotification
                )
            )
                .then(() => setCropPictureId(null))
                .catch(console.error);
        },
        [resetModalError, dispatch, cropPictureId, id, addNotification, pictureId]
    );

    const uploadImageButton = useCallback(
        (getInputProps: GetInputPropsFn) => (
            <FileLoadButton
                iconPosition={ButtonIconPosition.Left}
                isLoading={resizeStatus === Status.Fetching}
                icon={
                    <BlokoIconReplaceContainer>
                        <CameraOutlinedSize16 />
                    </BlokoIconReplaceContainer>
                }
                inputProps={getInputProps()}
            >
                {trls[TrlKeys.widgetImageUploadText]}
            </FileLoadButton>
        ),
        [resizeStatus, trls]
    );

    const disabledResizeButton = useCallback(() => {
        const disabled =
            cropPicture &&
            (cropPicture?.originalWidth < imageCropSettings.minWidth ||
                cropPicture?.originalHeight < imageCropSettings.minHeight);
        return (
            disabled && (
                <HoverTip
                    host={!process.env.SSR ? document.body : null}
                    render={() => trls[TrlKeys.invalidImage]}
                    activatorRef={activatorRef}
                >
                    <WidgetControlsButtonWrapper contentRef={activatorRef}>
                        <Button
                            disabled
                            icon={
                                <BlokoIconReplaceContainer>
                                    <PenSquareOutlinedSize16 />
                                </BlokoIconReplaceContainer>
                            }
                        />
                    </WidgetControlsButtonWrapper>
                </HoverTip>
            )
        );
    }, [cropPicture, imageCropSettings, trls]);

    const view = useCallback(
        () =>
            imageParams ? (
                <div
                    className={classNames(styles.widgetImage, {
                        [styles.widgetImageMagritte]: isMagritteEmployerPageHeaderExp,
                    })}
                >
                    <CdnImg
                        loading="lazy"
                        path={imageParams.path}
                        className={styles.widgetImageImage}
                        data-qa="widget-image__image"
                        alt={trls[TrlKeys.widgetImageName]}
                    />
                </div>
            ) : null,
        [imageParams, isMagritteEmployerPageHeaderExp, trls]
    );

    const loadArea = useCallback(
        (getInputProps: GetInputPropsFn) => (
            <LoadArea
                hintText={formatNewLine(
                    format(trls[TrlKeys.loadAreaDescription], {
                        '{0}': imageCropSettings.minWidth,
                        '{1}': imageCropSettings.minHeight,
                        '{2}': Math.floor(imageCropSettings.maxSizeBytes / 1024 / 1024),
                    })
                )}
                buttonText={trls[TrlKeys.uploadButton]}
                isLoading={resizeStatus === Status.Fetching}
                inputProps={getInputProps()}
            />
        ),
        [trls, imageCropSettings, resizeStatus]
    );

    const renderEditContent: DropzoneRenderFunction = useCallback(
        ({ getRootProps, getInputProps, isDragActive }) => {
            return (
                <div
                    className={classNames(styles.widgetImageDropContainer, {
                        [styles.widgetImageDropContainerDrop]: isDragActive,
                    })}
                    {...getRootProps()}
                >
                    <WidgetControls
                        upload={viewReady}
                        getMovedElementProps={getMovedElementProps}
                        edit={viewReady}
                        onEdit={() => setCropPictureId(pictureId || null)}
                        editOverride={disabledResizeButton()}
                        uploadOverride={uploadImageButton(getInputProps)}
                        name={trls[TrlKeys.widgetImageName]}
                        id={id}
                    />
                    {viewReady && view()}
                    {!viewReady && loadArea(getInputProps)}
                </div>
            );
        },
        [viewReady, getMovedElementProps, disabledResizeButton, uploadImageButton, trls, id, view, loadArea, pictureId]
    );

    const edit = useCallback(() => {
        return (
            <WidgetTip code={Widget.Picture}>
                <Dropzone
                    multiple={false}
                    disableClick={true}
                    accept={imageCropSettings.allowedMimeTypes}
                    onDrop={onUpload}
                >
                    {renderEditContent}
                </Dropzone>
                {!!cropPicture?.originalPath && (
                    <ImageCropPopup
                        error={modalError}
                        onClose={() => setCropPictureId(null)}
                        onDragStop={resetModalError}
                        onSave={onSavePicture}
                        visible
                        resizeInProgress={resizeStatus === Status.Fetching}
                        imageCropSettings={{
                            src: cropPicture.originalPath,
                            stateX: cropPicture.selectionLeft,
                            stateY: cropPicture.selectionTop,
                            stateWidth: cropPicture.selectionWidth,
                            stateHeight: cropPicture.selectionHeight,
                            originalWidth: cropPicture.originalWidth,
                            originalHeight: cropPicture.originalHeight,
                            ratio: imageCropSettings.widthHeightRatio,
                            minimumWidth: imageCropSettings.minWidth,
                            minimumHeight: imageCropSettings.minHeight,
                        }}
                        title={trls[TrlKeys.cropPopupTitle]}
                        description={trls[TrlKeys.cropPopupDescription]}
                        save={trls[TrlKeys.cropPopupSave]}
                        cancel={trls[TrlKeys.cropPopupCancel]}
                    />
                )}
            </WidgetTip>
        );
    }, [
        onUpload,
        renderEditContent,
        modalError,
        resetModalError,
        onSavePicture,
        cropPicture,
        resizeStatus,
        imageCropSettings,
        trls,
    ]);

    return <WidgetWrapper dragged={dragged} edit={edit} view={view} editMode={editMode} viewReady={viewReady} />;
};

export default translation(ImageWidget);
