enum Widget {
    Text = 'TEXT',
    Picture = 'PICTURE',
    Gallery = 'GALLERY',
    Video = 'VIDEO',
    Address = 'ADDRESS',
    Separator = 'SEPARATOR',
    Description = 'DESCRIPTION',
}

export type AddWidgetButtonType = Exclude<Widget, Widget.Description>;

export { Widget };
